import { Question } from "../pages/Calshare/Survey";

// Keep it at max 5 questions

export const priorImpressionsSurvey = [
  {
    id: "heard",
    type: "multiple-choices",
    description: "Comment as-tu entendu parler de Calshare ?",
    choices: [
      "Instagram",
      "Événement",
      "Bouche à oreille",
      "YouTube",
      "TikTok",
    ],
    mandatory: true,
  },
  {
    id: "reasons",
    type: "multiple-choices",
    description: "Quelle(s) raison(s) te motivent à utiliser l'app ?",
    choices: [
      "Facilité d'organisation",
      "Rentabilité des trajets",
      "Trouver des passagers",
      "Trouver un covoiturage",
      "Rencontrer d'autres sportifs.ves",
      "Voir les événements",
      "Partager les événements en story",
      "La faire connaître",
      "Seulement voir",
      "(Orga) Ajouter nos événements",
      "(Orga) Avoir des statistiques d'événements",
      "Autre",
    ],
    subQuestion: (value: string[] | undefined) => {
      if (value?.includes("Autre")) {
        return {
          type: "text",
          description: "Quelle autre raison ?",
        };
      }
    },
    mandatory: true,
  },
  {
    id: "wont-use",
    type: "multiple-choices",
    description: "Qu'est-ce qui ferait que tu n'utiliserait pas calshare ?",
    choices: [
      "Je ne sais pas vraiment ce que c'est",
      "L'app n'est pas assez connue",
      "Je ne suis pas sûr de trouver de trajet/passagers",
      "Je préfère m'organiser autrement",
      "Je ne veux pas payer/faire payer mes passagers",
      "Autre",
      "Aucune des raisons ci-dessus",
    ],
    subQuestion: (value: string[] | undefined) => {
      if (value?.includes("Autre")) {
        return {
          type: "text",
          description: "Quelle autre raison ?",
        };
      }
    },
    mandatory: true,
  },
  {
    id: "expectations",
    type: "text",
    description: "Qu'attends-tu de l'application ?",
    mandatory: true,
  },
] as Question[];
