import { useEffect, useState } from "react";
import { auth } from "../firebase";
import Authenticate from "../pages/Authenticate";
import Loading from "./Loading";

interface Props {
  children: JSX.Element;
}

const NeedAuthentication = ({ children }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setIsAuthenticated(user?.uid !== undefined);
    });
  }, [setIsAuthenticated]);

  return isAuthenticated === true ? (
    children
  ) : isAuthenticated === undefined ? (
    <div id="full-page-loading">
      <Loading />
      <h2>Chargement, on y est presque !</h2>
    </div>
  ) : (
    <>
      <p id="auth-message">Connecte-toi pour accèder à la page demandée</p>
      <Authenticate onAuthenticate={() => setIsAuthenticated(true)} />
    </>
  );
};

export default NeedAuthentication;
