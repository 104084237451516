import { useQuery } from "@tanstack/react-query";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db, functions } from "../firebase";
import { httpsCallable } from "firebase/functions";

type SurveyIds = "pre-use" | "post-use";

interface FullUser {
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  uuid: string;
  profilePic: string;
  bio: string;
  ig: string;
  accountType: string;
  surveys?: Partial<Record<SurveyIds, boolean>>;
  pastTrips?: string[];
}

interface ViewOnlyUser {
  username: string;
  firstname: string;
  uuid: string;
  profilePic: string;
  bio: string;
}

function useUser<T>(uuid: string, fullData?: boolean) {
  return useQuery({
    queryKey: [`user-${uuid}`, "users"],
    queryFn: async () => {
      let data;

      if (fullData) {
        const res = (
          await getDocs(
            query(collection(db, "Users"), where("uuid", "==", uuid))
          )
        ).docs.at(0);
        if (res) {
          data = res.data();
        }
      } else {
        const res = await httpsCallable(
          functions,
          "users-getUserInfoForView"
        )({
          uuids: [uuid],
        });
        if (res) {
          data = (res.data as ViewOnlyUser[])[0];
        }
      }

      return data as T;
    },
  });
}

export default useUser;
export { type SurveyIds, type FullUser, type ViewOnlyUser };
