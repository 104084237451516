import { Question } from "../pages/Calshare/Survey";

// Keep it at max 5 questions

export const postUseImpressionsSurvey = [
  {
    id: "experience-rating",
    type: "rating",
    description:
      "À combien évaluerais-tu ton expérience globale sur Calshare ?",
    mandatory: true,
  },
  {
    id: "expectations",
    type: "yes-no",
    description: "Répond-elle à tes attentes ?",
    subQuestion: (value: boolean) => {
      if (value === false) {
        return {
          type: "text",
          description: "Qu'est-ce qui pourrait faire en sorte que oui ?",
        };
      }
    },
    mandatory: true,
  },
  {
    id: "useful",
    type: "yes-no",
    description: "As-tu facilement trouvé un trajet et/ou des passagers ?",
    mandatory: true,
  },
  {
    id: "suggestion",
    type: "text",
    description: "Une suggestion qui améliorerait ton expérience utilisateur ?",
    mandatory: true,
  },
  {
    id: "word-of-mouth",
    type: "yes-no",
    description: "Vas-tu recommander Calshare à ton entourage ?",
    subQuestion: (value: boolean) => {
      if (value === false) {
        return {
          type: "text",
          description: "Qu'est-ce qui pourrait faire en sorte que oui ?",
        };
      }
    },
    mandatory: true,
  },
] as Question[];
