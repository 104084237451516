import { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase";
import { Loading } from "../../../components";

import "./organisations.scss";
import { Link } from "react-router-dom";

interface RegisteredOrganisation {
  username: string;
  profilePic: string;
}

const Organisations = () => {
  const [registeredOrganisations, setRegisteredOrganisations] = useState<
    RegisteredOrganisation[]
  >([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    async function fetchOrga() {
      try {
        const res = await httpsCallable(functions, "users-getOrganisations")();
        setRegisteredOrganisations(res.data as RegisteredOrganisation[]);
        setIsFetching(false);
      } catch {
        setIsFetching(false);
      }
    }

    fetchOrga();
  }, []);

  return (
    <>
      <section className="part-page">
        <div id="main-word">
          <h2>
            <span className="highlighted">Organisations,</span> soyez les
            bienvenues !
          </h2>
        </div>
        <p>
          Voici une page dédiée pour vous, afin que vous puissiez avoir les
          bases pour bien utiliser l'outil Calshare, concu pour vous aider vous
          et les passionnés :)
        </p>
      </section>
      <section className="full-page" id="registered-organisations">
        <h3>Elles ont créé leur compte :</h3>
        <div id="organisations-list-container">
          {isFetching ? (
            <Loading />
          ) : (
            <>
              <ul
                style={{
                  animationDuration: `${4 * registeredOrganisations.length}s`,
                }}
              >
                {registeredOrganisations.map((orga) => (
                  <li key={orga.username}>
                    <img src={orga.profilePic} alt={orga.username} />
                    <p>{orga.username}</p>
                  </li>
                ))}
              </ul>
              <ul
                id="second-list"
                style={{
                  animationDuration: `${4 * registeredOrganisations.length}s`,
                }}
              >
                {registeredOrganisations.map((orga) => (
                  <li key={orga.username}>
                    <img src={orga.profilePic} alt={orga.username} />
                    <p>{orga.username}</p>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </section>
      <section className="part-page organisations-step">
        <h3>D'abord, créons votre compte !</h3>
        <p>
          Vous pouvez bénéficier d'un compte dédié aux organisations, qui a
          l'avantage de vous rattacher à vos événements. Ce compte vous permet
          donc d'avoir votre nom visible avec vos événements, mais aussi des
          statistiques les concernant.
        </p>
        <div id="images">
          <img
            src="../../assets/orga-event-linking.png"
            alt="Rattachement de vos évents à votre compte"
          />
          <img
            src="../../assets/orga-stats.png"
            alt="Obtenez des statistiques de vos événements"
          />
        </div>
        <p>
          Pour créer votre compte, nous aurons besoin d'une adresse mail, afin
          d'autoriser la création.
          <br />
          Vous pouvez nous la communiquer simplement{" "}
          <a href="mailto:contact@swun.fr">par mail (contact@swun.fr)</a> ou via{" "}
          <a href="https://www.instagram.com/calshare_app">
            instagram (@calshare_app).
          </a>
        </p>
        <p>
          Une fois que nous avons validé votre e-mail, il vous reste simplement
          à{" "}
          <Link to="/join_as_organisation">
            accéder à la page de création de compte
          </Link>{" "}
          et de remplir vos informations !
        </p>
        <p>
          Une fois votre compte créé, vous pouvez vous connecter{" "}
          <Link to="/calshare/download" target="_blank" rel="noreferrer">
            via l'application Calshare
          </Link>
          , dans laquelle vous pourrez compléter votre profil !
        </p>
        <div id="images">
          <img
            src="../../assets/orga-account.png"
            alt="Votre compe organisation"
          />
        </div>
      </section>
      <section className="part-page organisations-step">
        <h3>Ensuite, ajoutons vos événements !</h3>
        <p>Le but principal est de pouvoir ajouter vos événements !</p>
        <p>
          Pour ce faire, rien de plus simple. Naviguez sur la page "centre
          d'événements", cliquer sur l'icône "+" en haut à droite, remplissez
          les informations et on est bon !
        </p>
        <p>
          Lors de l'ajout d'un événement, vous pouvez cocher la case "déclarer
          comme étant mon événement" afin de le rattacher à votre organisation,
          et ainsi avoir votre nom avec l'événement. Cette action est requise
          pour avoir des statistiques de vos événements.
        </p>
        <p>
          <strong>NB:</strong> "Suggérer un événement" passera par un processus
          de vérification chez nous, tandis que "ajouter un événement" le
          publiera directement, attention donc lors de l'utilisation de cette
          fonction
        </p>
        <p>
          Une fois votre événement posté, vous pouvez le retrouver depuis le
          "centre d'événements" et le partager, par exemple directement en story
          sur instagram ou facebook !
        </p>
        <div id="images">
          <video
            src="../../assets/orga-add-event_small.mp4"
            autoPlay
            playsInline
            controls={false}
            muted
            loop
          />
          <video
            src="../../assets/orga-share-event_small.mp4"
            autoPlay
            playsInline
            controls={false}
            muted
            loop
          />
        </div>
      </section>
      <section className="part-page organisations-step">
        <h3>Liens utiles</h3>
        <p>
          De nouvelles fonctionnalités arriveront par la suite. En attendant
          merci pour votre confiance et votre soutien envers ce projet ! Sa
          vraie force réside dans l'union :)
        </p>
        <p>Voici quelques liens qui peuvent vous être utiles :</p>
        <ul>
          <li>
            <Link to="/go_with_calshare" target="_blank" rel="noreferrer">
              Signaler votre événement
            </Link>
          </li>
          <li>
            <a
              href="https://apps.apple.com/fr/app/calshare/id6451190580"
              target="_blank"
              rel="noreferrer"
            >
              Calshare sur l'App Store
            </a>
          </li>
          <li>
            <Link
              to="https://play.google.com/store/apps/details?id=fr.swun.calshare&hl=fr"
              target="_blank"
              rel="noreferrer"
            >
              Calshare sur le Play Store
            </Link>
          </li>
        </ul>
      </section>
    </>
  );
};

export default Organisations;
