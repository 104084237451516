import { useState } from "react";
import Register from "./Register";
import Login from "./Login";
import "./authenticate.scss";

interface Props {
  onAuthenticate: () => void;
}

const Authenticate = ({ onAuthenticate }: Props) => {
  const [loginOrRegister, setLoginOrRegister] = useState<"register" | "login">(
    "login"
  );

  return loginOrRegister === "register" ? (
    <Register
      onAuthenticate={onAuthenticate}
      setLoginOrRegister={setLoginOrRegister}
    />
  ) : (
    <Login
      onAuthenticate={onAuthenticate}
      setLoginOrRegister={setLoginOrRegister}
    />
  );
};

export default Authenticate;
